
import React from "react";
import PromenadeTemplate from "../components/projects/commercial/PromenadeTemplate";

function Promenade() {
  return <PromenadeTemplate />
}

export default Promenade;

