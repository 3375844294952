import React, { useEffect, useState } from "react";
import PromenadeWeb from "./PromenadeWeb";
import PromenadeMobile from "./PromenadeMobile";
import Layout from "../../common/Layout";

function PromenadeTemplate() {
  const [deviceType, setDeviceType] = useState(null);
  const [deviceHeight, setDeviceHeight] = useState(null);

  useEffect(() => {
    window.addEventListener("orientationchange", setPageHeight);
    window.addEventListener("resize", setPageHeight);
    setPageHeight();

    return () => {
      window.removeEventListener("orientationchange", setPageHeight);
      window.removeEventListener("resize", setPageHeight);
    };
  }, []);

  function setPageHeight() {
    if (typeof window !== undefined) {
      setDeviceHeight(window.innerHeight);
    }
    if (typeof window !== "undefined" && typeof navigator !== "undefined") {
      // Your code that uses navigator
      if (navigator.maxTouchPoints > 0 && window.innerWidth <= 1280) {
        setDeviceType("Mobile");
      } else {
        setDeviceType("Web");
      }
    }
  }

  return (
    <Layout from="Promenade">
      <div
        className={`promenade-page ${
          deviceType === "Mobile" ? "mobile-device" : ""
        }`}
        style={{
          height:
            deviceType === "Mobile" && typeof window !== "undefined"
              ? deviceHeight
              : "",
        }}
      >
        {deviceType ? (
          deviceType === "Web" ? (
            <PromenadeWeb />
          ) : (
            <PromenadeMobile deviceHeight={deviceHeight} />
          )
        ) : typeof window !== "undefined" ? (
          window.innerWidth > 1280 ? (
            <PromenadeWeb />
          ) : (
            <PromenadeMobile />
          )
        ) : (
          <></>
        )}
      </div>
    </Layout>
  );
}

export default PromenadeTemplate;
